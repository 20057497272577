import React, { useState, useEffect } from "react";
import "./Styles/App.scss";
import Home from "./Components/Home";
import DineInn from "./Components/Buffet.jsx";
import Menu from "./Components/Menu";
import logo from "./Assets/Edited Logo.png";
import { HashRouter as Router, Switch, Route, NavLink } from "react-router-dom";
import Unknown from "./Components/Unknown.jsx";
import { AiOutlineMenu } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
function App() {
  const [mobileNav, setMobileNav] = useState(false);
  const [laptop, setLaptop] = useState(false);
  const [ipad, setIpad] = useState(false);

  // Toggles the mobile menu tab
  const closeNav = () => {
    setMobileNav(!mobileNav);
  };

  //Conditionally renders the mobile menu tab
  const mobileOn = () => {
    if (window.innerWidth <= 1200) {
      setIpad(true);
      setLaptop(false);
    } else {
      setIpad(false);
      setLaptop(true);
    }
  };

  window.addEventListener("resize", mobileOn);

  useEffect(() => {
    mobileOn();
    return () => {
      mobileOn();
    };
  }, []);

  return (
    <div className="app-container">
      {/* Mobile Tab */}
      {!mobileNav && ipad && (
        <button className="bars-tab" onClick={closeNav}>
          <AiOutlineMenu />
        </button>
      )}
      <Router>
        {mobileNav && (
          <nav className="mobile-nav">
            <button className="x-tab" onClick={closeNav}>
              <MdOutlineClose/>
            </button>
            <img alt="Logo" className="nav-logo" src={logo} />
            <ul>
              <li>
                <NavLink activeClassName="active-page" onClick={closeNav} exact to="/">
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active-page" onClick={closeNav} to="/buffet">
                  Buffet
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="active-page" onClick={closeNav} to="/menu">
                  Menu
                </NavLink>
              </li>
            </ul>
          </nav>
        )}
        {laptop && (
          <nav className="laptop-nav">
            <NavLink exact to="/">
              <img alt="Logo" className="nav-logo" src={logo} />
            </NavLink>
            <div className="nav-links">
              <NavLink activeClassName="active-page" exact to="/">
                Home
              </NavLink>
              <NavLink activeClassName="active-page" to="/buffet">
                Buffet
              </NavLink>
              <NavLink activeClassName="active-page" to="/menu">
                Menu
              </NavLink>
            </div>
          </nav>
        )}

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/buffet">
            <DineInn />
          </Route>
          <Route path="/menu">
            <Menu />
          </Route>
          <Route>
            <Unknown />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

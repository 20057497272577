export const appetizers = [
  {
    name: "Delicious Egg Roll",
    price: "$2.30",
    description: "",
  },
  {
    name: "Delicious Spring Roll",
    price: "$2.50",
    description: "",
  },
  {
    name: "Onion Rings",
    price: "$5.50",
    description: "",
  },
  {
    name: "French Fries",
    price: "$5.50",
    description: "",
  },
  {
    name: "Fish and Chips",
    price: "$14.50",
    description: "",
  },
  {
    name: "B.B.Q Pork Slices",
    price: "$15.99",
    description: "",
  },
  {
    name: "Chicken Wings (crispy)",
    price: "$13.99",
    description: "",
  },
  {
    name: "BBQ Chicken on the stick",
    price: "$14.99",
    description: "",
  },
  {
    name: "Bo Bo Tray For Two",
    price: "$26.99",
    description:
      "(2 Egg Rolls, Honey Garlic Spare Ribs, BBQ Chicken Stick, Crispy Chicken Wings, Fried Wontons and Chicken Balls)",
  },
];

export const soups = [
  {
    name: "Chicken, Beef or Pork Noodle Soup",
    price: "$5.50",
    description: "",
  },
  {
    name: "Hot and Sour Soup",
    price: "$6.50",
    description: "",
  },
  {
    name: "Wonton Soup with BBQ pork",
    price: "$5.50",
    description: "",
  },
  {
    name: "Egg Drop Soup",
    price: "$5.50",
    description: "",
  },
  {
    name: "Consomme Soup",
    price: "$3.00",
    description: "",
  },
];

export const sweetSour = [
  {
    name: "Sweet and Sour Chicken Balls",
    price: "$13.99",
    description: "",
  },
  {
    name: "Sweet and Sour Pork",
    price: "$13.99",
    description: "",
  },
  {
    name: "Sweet and Sour Breaded Shrimps",
    price: "$13.99",
    description: "",
  },
  {
    name: "Sweet and Sour Wonton",
    price: "$7.99",
    description: "",
  },
  {
    name: "Sweet and Sour Cream Cheese Wonton",
    price: "$11.99",
    description: "",
  },
];

export const chopSuey = [
  {
    name: "Chicken Chop Suey",
    price: "$11.99",
    description: "",
  },
  {
    name: "Beef Chop Suey",
    price: "$11.99",
    description: "",
  },
  {
    name: "BBQ Pork Chop Suey",
    price: "$11.99",
    description: "",
  },
  {
    name: "Mushroom Chop Suey",
    price: "$11.99",
    description: "",
  },
  {
    name: "Vegetable Chop Suey",
    price: "$11.99",
    description: "",
  },
  {
    name: "Shrimp and Mushroom Chop Suey",
    price: "$13.99",
    description: "",
  },
  {
    name: "House Special Chop Suey",
    price: "$13.99",
    description: "",
  },
];

export const honeyGarlic = [
  {
    name: "Honey Garlic Chicken Wings",
    price: "$14.99",
    description: "",
  },
  {
    name: "Honey Garlic Spare Ribs",
    price: "$16.99",
    description: "",
  },
  {
    name: "Honey Garlic Shrimps",
    price: "$14.99",
    description: "",
  },
  {
    name: "Honey Garlic Chicken Ball",
    price: "$14.99",
    description: "",
  },
];
export const friedRice = [
  {
    name: "Chicken Fried Rice",
    price: "$11.50",
    description: "",
  },
  {
    name: "Beef Fried Rice",
    price: "$11.50",
    description: "",
  },
  {
    name: "Pork Fried Rice",
    price: "$11.50",
    description: "",
  },
  {
    name: "Mushroom Fried Rice",
    price: "$11.50",
    description: "",
  },
  {
    name: "Vegetable Fried Rice",
    price: "$11.50",
    description: "",
  },
  {
    name: "Shrimp Fried Rice",
    price: "$13.99",
    description: "",
  },
  {
    name: "House Special Fried Rice",
    price: "$13.99",
    description: "",
  },
  {
    name: "Steamed Rice",
    price: "$3.99",
    description: "",
  },
];

export const vegetableAlmond = [
  {
    name: "Stir Fried Mixed Vegetables Almond",
    price: "$13.99",
    description: "",
  },
  {
    name: "Almond Guy Ding (Chicken)",
    price: "$13.99",
    description: "",
  },
  {
    name: "Almond Beef Ding",
    price: "$13.99",
    description: "",
  },
  {
    name: "Almond B.B.Q Pork Ding",
    price: "$13.99",
    description: "",
  },
  {
    name: "Almond Shrimp Ding",
    price: "$15.99",
    description: "",
  },
];

export const loMein = [
  {
    name: "Plain Lo-Mein",
    price: "$13.99",
    description: "",
  },
  {
    name: "Mixed Vegetable Lo-Mein",
    price: "$13.99",
    description: "",
  },
  {
    name: "Chicken Lo-Mein (with Mixed Vegetables)",
    price: "$14.99",
    description: "",
  },
  {
    name: "Pork Lo-Mein (with Mixed Vegetables)",
    price: "$14.99",
    description: "",
  },
  {
    name: "Beef Lo-Mein (with Mixed Vegetables)",
    price: "$14.99",
    description: "",
  },
  {
    name: "Shrimp Lo-Mein (with Mixed Vegetables)",
    price: "$16.99",
    description: "",
  },
  {
    name: "Shanghai Chow Mein (Chicken or Beef)",
    price: "$15.99",
    description: "",
  },
  {
    name: "Singapore Noodle",
    price: "$16.99",
    description: "",
  },
];

export const eggFuYoung = [
  {
    name: "Chicken Egg Fu Young",
    price: "$13.99",
    description: "",
  },
  {
    name: "B.B.Q Pork Egg Fu Young",
    price: "$13.99",
    description: "",
  },
  {
    name: "Beef Egg Fu Young",
    price: "$13.99",
    description: "",
  },
  {
    name: "Mushroom Egg Fu Young",
    price: "$13.99",
    description: "",
  },
  {
    name: "Shrimp Egg Fu Young",
    price: "$15.99",
    description: "",
  },
];

export const curry = [
  {
    name: "Curry Chicken with Vegetables",
    price: "$14.99",
    description: "",
  },
  {
    name: "Curry Beef with Vegetables",
    price: "$14.99",
    description: "",
  },
  {
    name: "Curry Shrimp with Vegetables",
    price: "$15.99",
    description: "",
  },
];

export const szechaun = [
  {
    name: "Kung Po (Chicken or Beef)",
    price: "$14.99",
    description: "",
  },
  {
    name: "Kung Pao Shrimps",
    price: "$15.99",
    description: "",
  },
  {
    name: "Szechuan Chicken Or Beef",
    price: "$14.99",
    description: "",
  },
];

export const beefDishes = [
  {
    name: "Beef or Chicken Mixed Vegetables",
    price: "$13.99",
    description: "",
  },
  {
    name: "Beef or Chicken with Mushroom",
    price: "$14.99",
    description: "",
  },
  {
    name: "Beef or Chicken with Green Pepper",
    price: "$13.99",
    description: "",
  },
  {
    name: "Beef or Chicken with Broccoli",
    price: "$13.99",
    description: "",
  },
  {
    name: "Beef or Chicken with Black Bean Sauce",
    price: "$13.99",
    description: "",
  },
  {
    name: "Beef or Chicken with Satay Sauce",
    price: "$13.99",
    description: "",
  },
  {
    name: "Black Pepper Sauce with Beef or Chicken",
    price: "$15.99",
    description: "",
  },
];

export const seafood = [
  {
    name: "Shrimp Lobster Sauce",
    price: "$17.99",
    description: "(Stir fried shrimp with minced porkin special sauce)",
  },
  {
    name: "Shrimp with Broccoli",
    price: "$15.99",
    description:
      "(Scallops, shrimp and mixed Chinese vegetables with our own sauce)",
  },
  {
    name: "Shrimp with Green Pepper",
    price: "$15.99",
    description: "Breaded scallops cooked with Chinese Vegetables)",
  },
];

export const chefSuggestions = [
  {
    name: "Cantonese Shrimp",
    price: "$19.99",
    description: "(Fresh Shrump stir fried with special sauce)",
  },
  {
    name: "Seafood Lover",
    price: "$20.99",
    description: "(Stir Fried Crab Meat, Scallops, Shrimps with Mushroom)",
  },
  {
    name: "Cantonese Chow Mein",
    price: "$16.99",
    description:
      "(Fresh Egg Noodle Pan Fried to Golden Brown, Prepared with B.B.Q Pork, Beef, Shrimp, Chicken, Mushroom, and Mix Fresh Vegetables)",
  },
  {
    name: "Cantonese Lo Mein",
    price: "$16.99",
    description: "",
  },
  {
    name: "Chow Har Kew",
    price: "$15.99",
    description: "(Fresh Large Shrimp with Mix Vegetables)",
  },
  {
    name: "Stir Fried Mixed Vegetables",
    price: "$12.99",
    description: "",
  },
  {
    name: "Tai Dop Woey",
    price: "$15.99",
    description:
      "(Stir Fried Chicken, Shrimp, B.B.Q Pork and Beef with Mix Vegetables)",
  },
  {
    name: "Butterfly Shrimp",
    price: "$18.99",
    description:
      "(Selected Jumbo Shrimp, Wrapped in Bacon and Egg, Pan Fried to Golden Brown)",
  },
  {
    name: "Moo Goo Guy Pan",
    price: "$14.99",
    description: "",
  },
  {
    name: "General Tao chicken",
    price: "$13.99",
    description: "",
  },
  {
    name: "Orange Chicken",
    price: "$13.99",
    description: "",
  },
  {
    name: "Lemon Chicken",
    price: "$13.99",
    description: "",
  },
  {
    name: "Sesame Chicken",
    price: "$13.99",
    description: "",
  },
  {
    name: "Chicken Soo Guy",
    price: "$14.99",
    description: "",
  },
];

export const combo1 = [
  "Egg Roll",
  "Sweet and Sour Chicken Balls",
  "Chicken Fried Rice",
  "Fortune Cookie",
];
export const combo2 = [
  "Egg Roll",
  "Chicken Soo Guy",
  "Chicken Fried Rice",
  "Fortune Cookie",
];
export const combo3 = [
  "Egg Roll",
  "Diced Chicken Vegetables and Almond",
  "Chicken Fried Rice",
  "Fortune Cookie",
];
export const combo4 = [
  "Egg Roll",
  "Lemon Chicken",
  "Chicken Fried Rice",
  "Fortune Cookie",
];
export const combo5 = [
  "Egg Roll",
  "Sweet and Sour Breaded Shrimp",
  "Chicken Fried Rice",
  "Fortune Cookie",
];
export const combo6 = [
  "Egg Roll",
  "Chicken Chop Suey",
  "Chicken Fried Rice",
  "Fortune Cookie",
];

export const dinnerOneA = [
  "Egg Roll",
  "Sweet and Sour Chicken Balls",
  "Diced Chicken with Vegetables Almonds",
  "Chicken Fried Rice",
  "Fortune Cookies",
];
export const dinnerOneB = [
  "Egg Roll",
  "Sweet and Sour Spare Ribs",
  "Chicken Chop Suey",
  "Chicken Fried Rice",
  "Fortune Cookies",
];
export const dinnerTwo = [
  "2 Egg Rolls",
  "Sweet & Sour Chicken Balls",
  "Chicken Chop Suey",
  "Chicken Fried Rice",
  "Fortune Cookies",
];

export const dinnerThree = [
  "3 Egg Rolls",
  "Sweet & Sour Chicken Balls",
  "Chicken w/ Mixed Vegetables Almond",
  "Lemon Chicken",
  "Chicken Fried Rice",
  "Fortune Cookies",
];

export const dinnerFour = [
  "4 Egg Rolls",
  "Chicken Chop Suey",
  "Beef Mixed Vegetables",
  "Breaded Shrimp",
  "Sweet & Sour Chicken Balls",
  "Chicken Fried Rice",
  "Fortune Cookies",
];

export const dinnerFive = [
  "5 Egg Rolls",
  "Sweet & Sour Chicken Balls",
  "Chicken Soo Guy with Gravy",
  "Chicken Chop Suey",
  "Breaded Shrimps",
  "Beef with Mix Vegetables",
  "Chicken Fried Rice",
  "Fortune Cookies",
];

export const dinnerSix = [
  "6 Egg Rolls",
  "Tai Dop Woey",
  "Honey Garlic Spare Ribs",
  "Chicken Soo Guy with Gravy",
  "Breaded Shrimps",
  "Sweet and Sour Chicken Balls",
  "Chicken Chop Suey",
  "House Special Fried Rice",
  "Fortune Cookies",
];

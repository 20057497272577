import React from "react";
import Footer from "../Components/Footer";
import { NavLink } from "react-router-dom";

export default function Home() {
  const contactScroll = () => {
    // const element = document.getElementById("#footer");
    const y = document.getElementById("footer").getBoundingClientRect().top + window.scrollY;
    window.scroll({
      top: y - 99,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="home-container">
        <div className="home-button-container">
          <NavLink className="menu-btn" to="/Menu">
            Menu
          </NavLink>
          <button
            className="contact-btn"
            onClick={() => {
              contactScroll();
            }}
          >
            Contact
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}

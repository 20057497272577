import React, { useState } from "react";
import Footer from "./Footer.jsx";
import {
  appetizers,
  soups,
  sweetSour,
  chopSuey,
  honeyGarlic,
  friedRice,
  vegetableAlmond,
  loMein,
  eggFuYoung,
  curry,
  szechaun,
  beefDishes,
  seafood,
  chefSuggestions,
  combo1,
  combo2,
  combo3,
  combo4,
  combo5,
  combo6,
  dinnerOneA,
  dinnerOneB,
  dinnerTwo,
  dinnerThree,
  dinnerFour,
  dinnerFive,
  dinnerSix,
} from "../Data/Items.jsx";
import springRollsImg from "../Assets/spring-rolls.png";
import beanImg from "../Assets/bean.png";
import beefImg from "../Assets/beef.png";
import chefImg from "../Assets/chef.png";
import chiliImg from "../Assets/chili.png";
import curryImg from "../Assets/curry.png";
import friedRiceImg from "../Assets/fried-rice.png";
import honeyImg from "../Assets/honey.png";
import scrambleImg from "../Assets/scramble.png";
import shrimpImg from "../Assets/shrimp.png";
import vegetablesImg from "../Assets/vegetables.png";
import soupImg from "../Assets/soup.png";
import sauceImg from "../Assets/sauce.png";
import noodlesImg from "../Assets/noodles.png";
import lunchBoxImg from "../Assets/lunch-box.png";
import familyDinnerImg from "../Assets/family.png";
export default function Menu() {
  const [appetizersOn, setAppetizersOn] = useState(false);
  const [soupsOn, setSoupsOn] = useState(false);
  const [sweetSourOn, setSweetSourOn] = useState(false);
  const [chopSueyOn, setChopSueyOn] = useState(false);
  const [honeyGarlicOn, setHoneyGarlicOn] = useState(false);
  const [friedRiceOn, setFriedRiceOn] = useState(false);
  const [vegetableAlmondOn, setVegetableAlmondOn] = useState(false);
  const [loMeinOn, setLoMeinOn] = useState(false);
  const [eggFuYoungOn, setEggFuYoungOn] = useState(false);
  const [curryOn, setCurryOn] = useState(false);
  const [szechaunOn, setSzechaunOn] = useState(false);
  const [beefDishesOn, setBeefDishesOn] = useState(false);
  const [seafoodOn, setSeafoodOn] = useState(false);
  const [chefSuggestionsOn, setChefSuggestionsOn] = useState(false);
  const [combo1On, setCombo1On] = useState(false);
  const [combo2On, setCombo2On] = useState(false);
  const [combo3On, setCombo3On] = useState(false);
  const [combo4On, setCombo4On] = useState(false);
  const [combo5On, setCombo5On] = useState(false);
  const [combo6On, setCombo6On] = useState(false);
  const [dinner1AOn, setDinner1AOn] = useState(false);
  const [dinner1BOn, setDinner1BOn] = useState(false);
  const [dinner2On, setDinner2On] = useState(false);
  const [dinner3On, setDinner3On] = useState(false);
  const [dinner4On, setDinner4On] = useState(false);
  const [dinner5On, setDinner5On] = useState(false);
  const [dinner6On, setDinner6On] = useState(false);

  return (
    <>
    <div className="menu-container">
      <h1 className="category-title">Menu Items</h1>
      {/* Appetizers */}
      <div className="item-card">
        <button
          onClick={() => {
            setAppetizersOn(!appetizersOn);
          }}
          className="item-title"
        >
          <h2>Apetizers</h2>
          <img alt="dish-icon" className="img-icon" src={springRollsImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {appetizersOn && (
          <div className="apetizers item-section">
            {appetizers.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish.name}</p>
                <p className="item-price">{dish.price}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Soups */}
      <div className="item-card">
        <button
          onClick={() => {
            setSoupsOn(!soupsOn);
          }}
          className="item-title"
        >
          <h2>Soups</h2>
          <img alt="dish-icon"className="img-icon" src={soupImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {soupsOn && (
          <div className="soups item-section">
            {soups.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish.name}</p>
                <p className="item-price">{dish.price}</p>
              </div>
            ))}
          </div>
        )}
        <div />
      </div>

      {/* Sweet & Sour */}
      <div className="item-card">
        <button
          onClick={() => {
            setSweetSourOn(!sweetSourOn);
          }}
          className="item-title"
        >
          <h2>Sweet and Sour</h2>
          <img alt="dish-icon"className="img-icon" src={sauceImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {sweetSourOn && (
          <div className="sweet-sour item-section">
            {sweetSour.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish.name}</p>
                <p className="item-price">{dish.price}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Chop Suey */}
      <div className="item-card">
        <button
          onClick={() => {
            setChopSueyOn(!chopSueyOn);
          }}
          className="item-title"
        >
          <h2>Chow Mein</h2>
          <img alt="dish-icon"className="img-icon" src={beanImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {chopSueyOn && (
          <div className="chow-mein item-section">
            {chopSuey.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish.name}</p>
                <p className="item-price">{dish.price}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Honey Garlic */}
      <div className="item-card">
        <button
          onClick={() => {
            setHoneyGarlicOn(!honeyGarlicOn);
          }}
          className="item-title"
        >
          <h2>Honey Garlic</h2>
          <img alt="dish-icon"className="img-icon" src={honeyImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {honeyGarlicOn && (
          <div className="honey-garlic item-section">
            {honeyGarlic.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish.name}</p>
                <p className="item-price">{dish.price}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Fried Rice */}
      <div className="item-card">
        <button
          onClick={() => {
            setFriedRiceOn(!friedRiceOn);
          }}
          className="item-title"
        >
          <h2>Fried Rice</h2>
          <img alt="dish-icon"className="img-icon" src={friedRiceImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {friedRiceOn && (
          <div className="fried-rice item-section">
            {friedRice.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish.name}</p>
                <p className="item-price">{dish.price}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Vegetable Almond */}
      <div className="item-card">
        <button
          onClick={() => {
            setVegetableAlmondOn(!vegetableAlmondOn);
          }}
          className="item-title"
        >
          <h2>Vegetable Almond</h2>
          <img alt="dish-icon"className="img-icon" src={vegetablesImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {vegetableAlmondOn && (
          <div className="vegetable-almond item-section">
            {vegetableAlmond.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish.name}</p>
                <p className="item-price">{dish.price}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Lo Mein */}
      <div className="item-card">
        <button
          onClick={() => {
            setLoMeinOn(!loMeinOn);
          }}
          className="item-title"
        >
          <h2>Lo Mein</h2>
          <img alt="dish-icon"className="img-icon" src={noodlesImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {loMeinOn && (
          <div className="loMein item-section">
            {loMein.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish.name}</p>
                <p className="item-price">{dish.price}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Egg Fu Young */}
      <div className="item-card">
        <button
          onClick={() => {
            setEggFuYoungOn(!eggFuYoungOn);
          }}
          className="item-title"
        >
          <h2>Egg-Fu-Young</h2>
          <img alt="dish-icon"className="img-icon" src={scrambleImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {eggFuYoungOn && (
          <div className="egg-fu-young item-section">
            {eggFuYoung.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish.name}</p>
                <p className="item-price">{dish.price}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Curry */}
      <div className="item-card">
        <button
          onClick={() => {
            setCurryOn(!curryOn);
          }}
          className="item-title"
        >
          <h2>Curry</h2>
          <img alt="dish-icon"className="img-icon" src={curryImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {curryOn && (
          <div className="curry item-section">
            {curry.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish.name}</p>
                <p className="item-price">{dish.price}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Szechaun */}
      <div className="item-card">
        <button
          onClick={() => {
            setSzechaunOn(!szechaunOn);
          }}
          className="item-title"
        >
          <h2>Szechaun</h2>
          <img alt="dish-icon"className="img-icon" src={chiliImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {szechaunOn && (
          <div className="szechaun item-section">
            {szechaun.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish.name}</p>
                <p className="item-price">{dish.price}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Beef or Chicken Dishes */}
      <div className="item-card">
        <button
          onClick={() => {
            setBeefDishesOn(!beefDishesOn);
          }}
          className="item-title"
        >
          <h2>Beef Or Chicken Dishes</h2>
          <img alt="dish-icon"className="img-icon" src={beefImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {beefDishesOn && (
          <div className="beef-dishes item-section">
            {beefDishes.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish.name}</p>
                <p className="item-price">{dish.price}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Seafood */}
      <div className="item-card">
        <button
          onClick={() => {
            setSeafoodOn(!seafoodOn);
          }}
          className="item-title"
        >
          <h2>Sea Food</h2>
          <img alt="dish-icon"className="img-icon" src={shrimpImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {seafoodOn && (
          <div className="seafood item-section">
            {seafood.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish.name}</p>
                <p className="item-price">{dish.price}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Chef's Suggestions */}
      <div className="item-card">
        <button
          onClick={() => {
            setChefSuggestionsOn(!chefSuggestionsOn);
          }}
          className="item-title"
        >
          <h2>Chef Suggestions</h2>
          <img alt="dish-icon"className="img-icon" src={chefImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {chefSuggestionsOn && (
          <div className="chef-suggestions item-section">
            {chefSuggestions.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish.name}</p>
                <p className="item-price">{dish.price}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      <h1 className="category-title">Special Combos for One</h1>
      {/* Combo 1*/}
      <div className="item-card">
        <button
          onClick={() => {
            setCombo1On(!combo1On);
          }}
          className="item-title"
        >
          <h2>Combo 1</h2>
          <img alt="dish-icon"className="img-icon" src={lunchBoxImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {combo1On && (
          <div className="chef-suggestions item-section">
            <p className="comboFamily-prices">$12.99</p>
            {combo1.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Combo 2 */}
      <div className="item-card">
        <button
          onClick={() => {
            setCombo2On(!combo2On);
          }}
          className="item-title"
        >
          <h2>Combo 2</h2>
          <img alt="dish-icon"className="img-icon" src={lunchBoxImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {combo2On && (
          <div className="chef-suggestions item-section">
            <p className="comboFamily-prices">$12.99</p>
            {combo2.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Combo 3 */}
      <div className="item-card">
        <button
          onClick={() => {
            setCombo3On(!combo3On);
          }}
          className="item-title"
        >
          <h2>Combo 3</h2>
          <img alt="dish-icon"className="img-icon" src={lunchBoxImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {combo3On && (
          <div className="chef-suggestions item-section">
            <p className="comboFamily-prices">$12.99</p>
            {combo3.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-price">{dish}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Combo 4 */}
      <div className="item-card">
        <button
          onClick={() => {
            setCombo4On(!combo4On);
          }}
          className="item-title"
        >
          <h2>Combo 4</h2>
          <img alt="dish-icon"className="img-icon" src={lunchBoxImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {combo4On && (
          <div className="chef-suggestions item-section">
            <p className="comboFamily-prices">$12.99</p>
            {combo4.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Combo 5 */}
      <div className="item-card">
        <button
          onClick={() => {
            setCombo5On(!combo5On);
          }}
          className="item-title"
        >
          <h2>Combo 5</h2>
          <img alt="dish-icon"className="img-icon" src={lunchBoxImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {combo5On && (
          <div className="chef-suggestions item-section">
            <p className="comboFamily-prices">$12.99</p>
            {combo5.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Combo 6 */}
      <div className="item-card">
        <button
          onClick={() => {
            setCombo6On(!combo6On);
          }}
          className="item-title"
        >
          <h2>Combo 6</h2>
          <img alt="dish-icon"className="img-icon" src={lunchBoxImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {combo6On && (
          <div className="chef-suggestions item-section">
            <p className="comboFamily-prices">$12.99</p>
            {combo6.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Family Meals */}
      <h1 className="category-title">Special Family Dinners</h1>

      {/* Dininer for One A */}
      <div className="item-card">
        <button
          onClick={() => {
            setDinner1AOn(!dinner1AOn);
          }}
          className="item-title"
        >
          <h2>Dinner For One A</h2>
          <img alt="dish-icon"className="img-icon" src={familyDinnerImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {dinner1AOn && (
          <div className="chef-suggestions item-section">
            <p className="comboFamily-prices">$15.99</p>
            {dinnerOneA.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Dininer for One B */}
      <div className="item-card">
        <button
          onClick={() => {
            setDinner1BOn(!dinner1BOn);
          }}
          className="item-title"
        >
          <h2>Dinner For One B</h2>
          <img alt="dish-icon"className="img-icon" src={familyDinnerImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {dinner1BOn && (
          <div className="chef-suggestions item-section">
            <p className="comboFamily-prices">$15.99</p>
            {dinnerOneB.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Dininer for Two */}
      <div className="item-card">
        <button
          onClick={() => {
            setDinner2On(!dinner2On);
          }}
          className="item-title"
        >
          <h2>Dinner For Two</h2>
          <img alt="dish-icon"className="img-icon" src={familyDinnerImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {dinner2On && (
          <div className="chef-suggestions item-section">
            <p className="comboFamily-prices">$30.99</p>
            {dinnerTwo.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Dininer for Three */}
      <div className="item-card">
        <button
          onClick={() => {
            setDinner3On(!dinner3On);
          }}
          className="item-title"
        >
          <h2>Dinner For Three</h2>
          <img alt="dish-icon"className="img-icon" src={familyDinnerImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {dinner3On && (
          <div className="chef-suggestions item-section">
            <p className="comboFamily-prices">$46.99</p>
            {dinnerThree.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Dininer for One Four */}
      <div className="item-card">
        <button
          onClick={() => {
            setDinner4On(!dinner4On);
          }}
          className="item-title"
        >
          <h2>Dinner For Four</h2>
          <img alt="dish-icon"className="img-icon" src={familyDinnerImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {dinner4On && (
          <div className="chef-suggestions item-section">
            <p className="comboFamily-prices">$61.99</p>
            {dinnerFour.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Dininer for Five */}
      <div className="item-card">
        <button
          onClick={() => {
            setDinner5On(!dinner5On);
          }}
          className="item-title"
        >
          <h2>Dinner For Five</h2>
          <img alt="dish-icon"className="img-icon" src={familyDinnerImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {dinner5On && (
          <div className="chef-suggestions item-section">
            <p className="comboFamily-prices">$77.99</p>
            {dinnerFive.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish}</p>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Dininer for Six */}
      <div className="item-card">
        <button
          onClick={() => {
            setDinner6On(!dinner6On);
          }}
          className="item-title"
        >
          <h2>Dinner For Six</h2>
          <img alt="dish-icon"className="img-icon" src={familyDinnerImg} />
          {/* <FontAwesomeIcon className="btn-icon" icon={faChevronCircleDown} /> */}
        </button>
        {dinner6On && (
          <div className="chef-suggestions item-section">
            <p className="comboFamily-prices">$93.99</p>
            {dinnerSix.map((dish, i) => (
              <div key={i} className="items">
                <p className="item-name">{dish}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
    <Footer/>
    </>
  );
}
